import {Plan} from '@rechargeapps/storefront-client';

const cachedPlans = new Map<number, Plan>();

/**
 * Caches the product and its variants in the optimistic cache.
 * Also preloads the images for each variant.
 * This allows us to show the product details immediately after adding to cart,
 * without waiting for the server response.
 *
 * @param product - The product state to be cached.
 */
export function optimisticCachePlan(plan: Plan) {
  const id = plan.external_plan_id;
  if (!id) return;
  cachedPlans.set(id, plan);
}

/**
 * Retrieves the cached product for the given variant ID.
 * @param variantId - The ID of the variant.
 * @returns The cached product, or undefined if not found.
 */
export function getCachedPlan(shopifyPlanId: number) {
  return cachedPlans.get(shopifyPlanId);
}
