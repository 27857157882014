import {
  Cart,
  CartLineItem,
  SellingPlan,
  SellingPlanAllocation,
  UpdateLineItemInput,
} from '@/cart/types';
import {calculateItemCount, calculateTotalPrice} from '@/cart/util';
import {getCachedPlan} from './optimistic-cache-plan';

/**
 * Synchronously updates a line item in the cart and returns the updated cart.
 * This allows us to display the updates to the cart before the API request is made,
 * creating the illusion of an instant response.
 * @param item - The updated line item information.
 * @param currentCart - The current cart object.
 * @returns The updated cart object.
 */
export function optimisticUpdateLineItem(
  item: UpdateLineItemInput,
  currentCart: Cart
): Cart {
  const cartItems = currentCart.items;
  const updatedItems: CartLineItem[] = [];
  const {id, line, selling_plan} = item;

  for (let i = 0; i < cartItems.length; i++) {
    const lineItem = cartItems[i];
    const matchesId = !!id && id === lineItem.id.toString();
    const matchesLine = !!line && line === i + 1;
    const planAllocation = updateSellingPlanAllocation(lineItem, selling_plan);

    let newItem = lineItem;

    if (matchesId || matchesLine) {
      newItem = {
        ...lineItem,
        price: planAllocation?.price ?? lineItem.price,
        properties: {
          ...lineItem.properties,
          ...item.properties,
        },
        quantity: item.quantity ?? lineItem.quantity,
        selling_plan_allocation: planAllocation,
      };
    }

    if (newItem.quantity === 0) continue;

    updatedItems.push(newItem);
  }

  const subtotal = calculateTotalPrice(updatedItems);

  return {
    ...currentCart,
    item_count: calculateItemCount(updatedItems),
    items: updatedItems,
    items_subtotal_price: subtotal,
    total_price: subtotal - currentCart.total_discount,
  };
}

/**
 * Updates the selling plan allocation for a given line item.
 * If a `sellingPlanId` is provided, it retrieves the selling plan with the specified ID.
 * Otherwise, it uses the selling plan allocation from the line item.
 * It calculates the discounted price based on the selling plan and returns the updated selling plan allocation.
 *
 * @param lineItem - The line item to update the selling plan allocation for.
 * @param sellingPlanId - The ID of the selling plan to use for the update.
 * @returns The updated selling plan allocation, or `undefined` if no selling plan is found.
 */
function updateSellingPlanAllocation(
  lineItem: CartLineItem,
  sellingPlanId?: number | null
): SellingPlanAllocation | undefined {
  const plan = sellingPlanId
    ? getSellingPlan(sellingPlanId)
    : lineItem.selling_plan_allocation?.selling_plan;
  const discountPercentage = plan?.price_adjustments[0].value ?? 100;
  const originalPrice =
    lineItem.selling_plan_allocation?.compare_at_price ?? lineItem.price;
  const price = originalPrice * (1 - discountPercentage / 100);

  return plan
    ? {
        ...lineItem.selling_plan_allocation!,
        price,
        selling_plan: plan,
      }
    : undefined;
}

/**
 * Retrieves the selling plan details based on the provided selling plan ID.
 * @param sellingPlanId - The ID of the selling plan.
 * @returns The selling plan object containing the plan details.
 */
function getSellingPlan(sellingPlanId: number) {
  const cachedPlan = getCachedPlan(sellingPlanId);
  if (!cachedPlan) return;

  return {
    name: cachedPlan.external_plan_name ?? cachedPlan.title,
    description: '',
    id: sellingPlanId,
    recurring_deliveries: true,
    fixed_selling_plan: false,
    price_adjustments: [
      {
        value: parseInt(cachedPlan.discount_amount ?? '0'),
        value_type: 'percentage',
        position: 0,
      },
    ],
    options: [
      {
        name: 'Recharge Plan Id',
        value: cachedPlan.id.toString(),
      },
    ],
  } satisfies SellingPlan;
}
