import {createStorefrontClient} from '@shopify/hydrogen-react';

const SHOP =
  (import.meta.env.VITE_PUBLIC_STORE_DOMAIN as string) ??
  'shop.earthbreezedev.com';
const TOKEN =
  (import.meta.env.VITE_PUBLIC_STOREFRONT_API_TOKEN as string) ??
  'stubbed-storefront-api-token';

const _client = createStorefrontClient({
  storeDomain: SHOP,
  publicStorefrontToken: TOKEN,
});

/**
 * Client for fetching data from the Shopify Storefront GraphQL API
 * @see https://shopify.dev/docs/storefront-api
 */
export const client = {
  /**
   * Fetches data from the Shopify Storefront GraphQL API
   * @param query - GraphQL query
   * @param variables - GraphQL variables
   * @returns GraphQL response
   */
  async query<T>(
    query: string,
    variables: Record<string, unknown> = {}
  ): Promise<T> {
    const url = _client.getStorefrontApiUrl();
    const headers = _client.getPublicTokenHeaders();
    const body = JSON.stringify({query, variables});
    const method = 'POST';

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const {data} = (await response.json()) as {data: T};
    return data;
  },
};
