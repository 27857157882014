import {Cart, CartLineItem, Image, LineItemInput} from '@/cart/types';
import {calculateItemCount, calculateTotalPrice} from '@/cart/util';
import {ProductState, VariantState} from '@/product/types';
import {logInfo} from '@/utilities/log';
import {getCachedProduct} from './optimistic-cache-product';

/**
 * Represents a placeholder item in the cart.
 * This is used to display the cart with the new items before the request is made.
 */
const placeholderItem = {
  featured_image: {
    alt: 'Placeholder Image',
    url: 'https://cdn.shopify.com/s/files/1/0665/0196/8109/files/006-07-230920_dd7c7c02-fa26-4ca6-9392-e3b028eea864.png?v=1704205820',
  },
  id: -1,
  price: -1,
  product_id: -1,
  product_title: 'Placeholder Product',
  properties: {},
  quantity: 1,
  variant_title: '',
} as CartLineItem;

/**
 * Synchronously adds line items to a cart and returns the updated cart.
 * This allows us to display the updates to the cart before the request is made,
 * creating the illusion of an instant response.
 *
 * @param items - An array of line items to be added.
 * @param currentCart - The current cart object.
 * @param placeholder - A placeholder item to be used for each new item.
 * @returns The updated cart object with the added line items.
 */
export function optimisticAddLineItems(
  items: LineItemInput[],
  currentCart: Cart
): Cart {
  const cartItems = currentCart.items;

  // create a placeholder item for each new item
  const addedItems: CartLineItem[] = items
    .filter((item) => {
      return !cartItems.some((lineItem) => itemMatches(item, lineItem));
    })
    .map(getOptisticItem);

  // calculate the updated quantity for each existing item
  const updatedItems = cartItems.map((lineItem) => {
    const update = items.find((item) => itemMatches(item, lineItem));
    if (!update) return lineItem;

    return {
      ...lineItem,
      quantity: lineItem.quantity + update.quantity,
    };
  });

  const newItems = [...addedItems, ...updatedItems];

  return {
    ...currentCart,
    item_count: calculateItemCount(newItems),
    items: newItems,
    total_price: calculateTotalPrice(newItems),
  };
}

/**
 * Checks if an added line item matches an existing line item in the cart.
 * This allows us to determine whether the item will be added or updated.
 *
 * @param item - The item to compare.
 * @param lineItem - The line item to compare against.
 * @returns A boolean indicating whether the item matches the line item.
 */
function itemMatches(item: LineItemInput, lineItem: CartLineItem): boolean {
  return (
    item.id === lineItem.id &&
    item.selling_plan ===
      lineItem.selling_plan_allocation?.selling_plan.id.toString() &&
    Object.entries(item.properties ?? {}).every(
      ([key, value]) => lineItem.properties?.[key] === value
    )
  );
}

/**
 * Retrieves the optimistic item for a given line item input.
 * This is used to display the cart with the new items before the request is made.
 * If the product is not in the cache, a placeholder item is returned.
 *
 * @param itemInput - The line item input.
 * @returns The optimistic cart line item.
 */
function getOptisticItem(itemInput: LineItemInput): CartLineItem {
  const optimisticProductCache = getCachedProduct(itemInput.id.toString());

  if (!optimisticProductCache) {
    logInfo('Warn: OptimisticProductCacheMiss', {
      itemInput,
      placeholderItem,
    });
    return placeholderItem;
  }

  const optimisticItem = createOptistimicCartItem({
    product: optimisticProductCache.product,
    variant: optimisticProductCache.variant,
    itemInput,
  });

  logInfo('CreatedOptimisticCartItem', {
    itemInput,
    optimisticProductCache,
    optimisticItem,
  });

  return optimisticItem;
}

/**
 * Creates an optimistic cart item based on the provided inputs.
 * @param product - The product state.
 * @param variant - The variant state.
 * @param itemInput - The line item input.
 * @returns The created optimistic cart item.
 */
function createOptistimicCartItem({
  product,
  variant,
  itemInput,
}: {
  product: ProductState;
  variant: VariantState;
  itemInput: LineItemInput;
}): CartLineItem {
  const {id, properties, quantity, selling_plan} = itemInput;
  const {featuredImage, id: productId, title: productTitle} = product;
  const {image, selectedOptions, title: variantTitle} = variant;
  const sellingPlanAllocation = selling_plan
    ? createOptimisticSellingPlanAllocation(variant, selling_plan.toString())
    : undefined;
  const discountPercentage =
    (sellingPlanAllocation?.selling_plan.price_adjustments[0]?.value ?? 0) /
    100;

  // convert to cents and adjust for selling plan discount
  const price = variant.price * 100 * (1 - discountPercentage);

  return {
    discounted_price: price,
    discounts: [],
    featured_image: (image ?? featuredImage) as Image,
    final_line_price: price,
    final_price: price,
    gift_card: false,
    grams: -1,
    handle: '',
    id,
    image: image?.url ?? featuredImage?.url ?? '',
    key: '',
    line_level_discount_allocations: [],
    line_level_total_discount: -1,
    line_price: price,
    options_with_values: selectedOptions,
    original_line_price: price,
    original_price: price,
    price,
    product_description: '',
    product_has_only_default_variant: false,
    product_id: parseInt(productId),
    product_title: productTitle,
    product_type: '',
    properties: properties ?? {},
    quantity,
    requires_shipping: true,
    selling_plan_allocation: sellingPlanAllocation,
    sku: '',
    taxable: true,
    title: `${productTitle} ${variantTitle}`,
    total_discount: -1,
    url: '',
    variant_id: id,
    variant_options: selectedOptions.map(({value}) => value),
    variant_title: variantTitle ?? '',
    vendor: '',
  };
}

/**
 * Creates an optimistic selling plan allocation object based on the provided variant and selling plan ID.
 * @param variant - The variant state object.
 * @param sellingPlanId - The ID of the selling plan.
 * @returns The optimistic selling plan allocation object.
 */
function createOptimisticSellingPlanAllocation(
  variant: VariantState,
  sellingPlanId: string
) {
  const sellingPlanAllocation = variant.sellingPlanAllocations.find(
    ({sellingPlan}) => sellingPlan.id === sellingPlanId
  );
  if (!sellingPlanAllocation) return;

  const {id, name, options, priceAdjustments} =
    sellingPlanAllocation.sellingPlan;
  const discountPercentage =
    priceAdjustments[0]?.adjustmentValue.adjustmentPercentage ?? 0;
  const price = variant.price * (1 - discountPercentage / 100);

  return {
    price_adjustments: [],
    price: price * 100,
    compare_at_price: variant.price * 100,
    per_delivery_price: price * 100,
    selling_plan: {
      description: '',
      recurring_deliveries: false,
      fixed_selling_plan: false,
      id: parseInt(id),
      name,
      options,
      price_adjustments: priceAdjustments.map((adjustment, i) => ({
        position: i,
        value: adjustment.adjustmentValue.adjustmentPercentage,
      })),
    },
  };
}
