import {signal} from '@preact/signals';

import {DrawerController} from './Drawer';

/**
 * Controller for components that open and close, such as a drawer, menu or modal.
 * Uses a Preact signal to track state, so it can be used outside of the components.
 * @returns the drawer controller
 *
 * @example Using the signal to control a component:
 * ```tsx
 * const drawer = new ComponentToggleSignal();
 * render(<Drawer controller={drawer} />, target);
 * drawer.open(); // opens the drawer
 * drawer.close(); // closes the drawer
 * ```
 *
 * @example Subscribing to the signal:
 * ```tsx
 * const drawer = new ComponentToggleSignal();
 * effect(() => {
 *  if (drawer.isOpen) {
 *    // do something when the drawer is open
 *  } else {
 *    // do something when the drawer is closed
 *  }
 * });
 * ```
 */
export default class ComponentToggleSignal implements DrawerController {
  _isOpen = signal(false);

  get isOpen() {
    return this._isOpen.value;
  }

  open() {
    this._isOpen.value = true;
  }

  close() {
    this._isOpen.value = false;
  }

  toggle() {
    this._isOpen.value = !this._isOpen.value;
  }
}
