import classNames from 'classnames';

export default Scrim;

/**
 * Preact scrim component.
 * Used to darken the background when a drawer or modal is open.
 * @param param0 - the props
 * @returns  the scrim component
 * @see Drawer
 * @see ScrimProps
 */
function Scrim({className, onClick, showScrim}: ScrimProps) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        className,
        'scrim',
        {
          'opacity-60': showScrim,
          'pointer-events-none opacity-0': !showScrim,
        }
      )}
    ></div>
  );
}

/**
 * The props for the scrim component
 * @param showScrim - if the scrim is visible
 * @param onClick - the click handler
 * @see Scrim
 */
export type ScrimProps = {
  showScrim: boolean;
  className?: string;
  onClick?: () => void;
};
