import {logError} from '@/utilities/log';

/**
 * Represents a task queue that executes tasks in sequence.
 * This is useful for ensuring that tasks are executed in order.
 */
export class TaskQueue {
  private running = false;
  private readonly queue: (() => Promise<unknown>)[] = [];

  get isEmpty() {
    return this.queue.length === 0 && !this.running;
  }

  constructor(private readonly onEmpty?: () => void | Promise<void>) {}

  /**
   * Enqueues a task to be executed.
   * This will start executing the task immediately if no other tasks are running.
   * If other tasks are running, the task will be executed when they complete.
   * @param task - The task to enqueue.
   * @example
   * ```ts
   * const queue = new TaskQueue();
   *
   * queue.enqueue(async () => {
   *  await new Promise((resolve) => setTimeout(resolve, 1000));
   *  console.log('Task 1 complete');
   * });
   *
   * queue.enqueue(async () => {
   *  await new Promise((resolve) => setTimeout(resolve, 1000));
   *  console.log('Task 2 complete');
   * });
   *
   * // Task 1 complete
   * // Task 2 complete
   * ```
   */
  public enqueue(task: () => Promise<unknown>) {
    this.queue.push(task);
    void this.next();
  }

  /**
   * Enqueues an asynchronous task and returns a promise that resolves with the result of the task.
   * This allows us to use the task queue with a return value.
   *
   * @param task - The task to be executed asynchronously.
   * @returns A promise that resolves with the result of the task.
   */
  public enqueueAsync<T>(task: () => T | Promise<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      this.enqueue(async () => {
        try {
          resolve(await task());
        } catch (error) {
          logError(error, {message: 'RejectedAsyncTask'});
          reject(error);
        }
      });
    });
  }

  /**
   * Clears the task queue by removing all tasks.
   */
  public clear() {
    this.queue.length = 0;
  }

  /**
   * Executes the next task in the queue.
   * If there are no tasks in the queue, it calls the `onEmpty` callback if provided.
   * If an error occurs while executing the task, it logs the error using the `logError` function.
   */
  private async next() {
    try {
      if (!this.running && this.queue.length) {
        this.running = true;
        const task = this.queue.shift();

        await task!();

        this.running = false;
        await this.next();
      } else if (this.isEmpty) {
        await this.onEmpty?.call(this);
      }
    } catch (error) {
      logError(error, {message: 'ErrorRunningTask'});
    }
  }
}
