import {logError} from '@/utilities/log';

export type StorefrontClient = {
  query: <T>(query: string, variables: Record<string, unknown>) => Promise<T>;
};

const query = `# gql
mutation cartDiscountCodesUpdate($id: ID!, $discountCodes: [String!]) {
  cartDiscountCodesUpdate(cartId: $id, discountCodes: $discountCodes) {
    cart {
      id
      discountCodes {
        applicable
        code
      }
    }
    userErrors {
      field
      message
    }
  }
}
`;

type CartDiscountCodesUpdate = {
  userErrors: unknown[];
};

/**
 * Updates the discount codes on a cart
 * @param client - Storefront API Client
 * @param cartId - Cart ID
 * @param discountCodes - Discount codes to apply
 * @throws Error if the discount codes could not be updated
 */
export async function discountCodesUpdateHandler(
  client: StorefrontClient,
  cartId: string,
  discountCodes: string[]
): Promise<void> {
  try {
    const response = await client.query<{
      cartDiscountCodesUpdate: CartDiscountCodesUpdate;
    }>(query, {
      id: cartId,
      discountCodes,
    });
    const errors = response.cartDiscountCodesUpdate.userErrors;
    if (errors && errors.length > 0) {
      throw new Error(JSON.stringify(errors));
    }
  } catch (error) {
    logError(error, {message: 'ErrorUpdatingDiscountCodes'});
    throw error;
  }
}
