/**
 * Creates a preview image URL by appending the width query parameter to the original URL.
 * This allows us to use the same, small image urls that should have already been loaded by liquid.
 *
 * @param url - The original image URL.
 * @returns The preview image URL with the width query parameter.
 */
export function createShopifyImageUrl(url: string, width: number) {
  const prefix = url.includes('?') ? '&' : '?';
  return `${url}${prefix}width=${width}`;
}
