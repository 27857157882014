import {useEffect, useRef} from 'preact/hooks';

/**
 * Prevent the body from scrolling depending on a boolean state.
 * This is particularly useful for drawers, modals, and other components that
 * should prevent the body from scrolling when they are open.
 * @example
 * ```ts
 * const [isDrawerOpen, setIsDrawerOpen] = useState(false);
 * useScrollCapture(isDrawerOpen);
 * ```
 */
export default function useScrollCapture(captureScroll: boolean) {
  // Prevent the body from scrolling when captureScroll is true
  const scrollPosition = useRef(window.scrollY);
  useEffect(() => {
    const bodyStyle = document.body.style;
    if (captureScroll) {
      scrollPosition.current = window.scrollY;
      bodyStyle.overflow = 'hidden';
      bodyStyle.position = 'fixed';
      bodyStyle.top = `-${scrollPosition.current}px`;
      bodyStyle.width = '100%';
    } else {
      bodyStyle.removeProperty('overflow');
      bodyStyle.removeProperty('position');
      bodyStyle.removeProperty('top');
      bodyStyle.removeProperty('width');
      window.scrollTo(0, scrollPosition.current);
    }
  }, [captureScroll]);
}
