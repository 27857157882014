import {CartLineItem} from './types';

/**
 * Calculates the total price of items in the cart.
 * If any item has a price of -1, returns -1.
 * This allows us to display a loading view while the cart is being updated.
 *
 * @param items - An array of cart line items.
 * @returns The total price of the items.
 */
export function calculateTotalPrice(items: CartLineItem[]): number {
  if (items.some((item) => item.price === -1)) return -1;
  return items.reduce((total, item) => total + item.price * item.quantity, 0);
}

/**
 * Calculates the total item count in the cart.
 *
 * @param items - An array of cart line items.
 * @returns The total item count.
 */
export function calculateItemCount(items: CartLineItem[]): number {
  return items.reduce((total, item) => total + item.quantity, 0);
}
