const preloadedUrls = new Set<string>();

/**
 * Preloads an image by creating a new Image object and setting its source to the specified URL.
 * This helps to improve the loading performance of the image when it is actually displayed.
 * If the image has already been preloaded, it will not be preloaded again.
 *
 * @param url - The URL of the image to preload.
 */
export function preloadImage(url: string) {
  if (preloadedUrls.has(url)) {
    return;
  }
  preloadedUrls.add(url);
  const img = new Image();
  img.src = url;
}
